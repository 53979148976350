<template>
	<div class="container">
		<div class="imageBanner animationBlockFadeUp" v-lazy:background-image="image"></div>
	</div>
</template>



<style lang="sass">
	@import '@/assets/new_sass/ImageBanner'
</style>

<script>
	export default {
		props: ['image'],
	}
</script>